// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously, sendSignInLinkToEmail } from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database";
const queryString = require('query-string');
const Add2Calendar = require('add2calendar');
const bootstrap = require('bootstrap');


// Initialize Realtime Database and get a reference to the service


console.log(location.origin);
//=> '?foo=bar'

const parsed = queryString.parse(location.search);
console.log(parsed);
//=> {foo: 'bar'}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyApOHVE8GEgrAHN3V7DarWYnXn0AOq6kao",
    authDomain: "lilliandmatt.firebaseapp.com",
    projectId: "lilliandmatt",
    storageBucket: "lilliandmatt.appspot.com",
    messagingSenderId: "640252518772",
    appId: "1:640252518772:web:176d0f0b0d04d024cb8c21",
    measurementId: "G-QXEC1RZ83N",
    databaseURL: "https://lilliandmatt-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${location.origin}?emailverified=true`,
    // This must be true.
    handleCodeInApp: true,
    /* iOS: {
      bundleId: 'com.example.ios'
    },
    android: {
      packageName: 'com.example.android',
      installApp: true,
      minimumVersion: '12'
    },
    dynamicLinkDomain: 'lilliandmatt.com' */
};

var singleEventArgs = {
    title: 'Lilli and Matt\'s Wedding',
    start: 'August 12, 2023 9:30',
    end: 'August 12, 2023 23:30',
    location: 'St Anne\'s College, 56 Woodstock Rd, Oxford OX2 6HS, UK',
    description: 'We would be delighted if you could join us for our special day - please save the date and await our formal invitation with more details.',
    isAllDay: true,
};
var singleEvent = new Add2Calendar(singleEventArgs);

// to get actual url
let googleEvent = singleEvent.getGoogleUrl(); // https://www.google.com/calendar/render?action=TEMPLATE&text=...
let iCalEvent = singleEvent.getICalUrl(); // data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0A...
let outlookEvent = singleEvent.getOutlookUrl(); // data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0A...

let googleButton = document.getElementById('googleButton');
let outlookButton = document.getElementById('outlookButton');
let iCalButton = document.getElementById('iCalButton');

console.log(googleEvent, googleButton);

googleButton.href = googleEvent;
outlookButton.href = outlookEvent;
iCalButton.href = iCalEvent;



const auth = getAuth();

function signin(email) {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then((success) => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email);
            // ...
            console.log(auth);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error)
            // ...
        });
}

window.signin = signin;

function replaceGlobally(original, searchTxt, replaceTxt) {
    const regex = new RegExp(searchTxt, 'g');
    return original.replace(regex, replaceTxt) ;
  }

//window.signin = signin('wallermatthewj@gmail.com');

function checkUserData(email) {
    //console.log(email)
    const cleanEmail = email.split('.').join('');
    //console.log(cleanEmail);
    const path = 'users/' + cleanEmail
    //console.log('path is',path);
    const db = database;
    const postListRef = ref(db, path);
    return get(postListRef)
        .then((snapshot) => {
            return snapshot.exists()
        })
        .catch((error) => {
            console.error(error);
            return error
        });
}

function writeUserData(name, email) {
    const cleanEmail = email.split('.').join('');
    //console.log(cleanEmail);
    const path = 'users/' + cleanEmail
    const db = database;
    const postListRef = ref(db, path);
    return set(postListRef, {
        name,
        email
    });
}

function writeDeclineData(uid, name) {
    const db = database;
    const cleanName = name.split(' ').join('');
    const postListRef = ref(db, 'declines/' && cleanName);
    //const newPostRef = push(postListRef);
    return set(postListRef, {
        name
    })
}

export function createAlert(type, container, text) {
    const existingAlert = document.getElementsByClassName('alert')[0];
    existingAlert?.remove();
    const alert = document.createElement('div');
    alert.innerText = text;
    alert.classList.add('alert', `alert-${type}`);
    container.appendChild(alert);
}

const form = document.getElementById('registerEmail');
const emailField = document.getElementById('floatingInput');
const nameField = document.getElementById('floatingName');

function submitForm(form) {
    console.log(form)
    const name = nameField.value;
    const email = emailField.value;
    console.log(name, email);
    return signInAnonymously(auth)
        .then(() => {
            return checkUserData(email);
        })
        .then((exists) => {
            console.log(exists);
            if (exists) {
                throw 'Email Already Exists!'
            }
            return writeUserData(name, email);
        })
        .then((result3) => {
            console.log('Posting Success!')
            console.log('success');
            createAlert('success', form, "Successfully Registered!")
        })
        .catch((error) => {
            console.log(error);
            createAlert('danger', form, error)
        })
}


function loadingButton(buttonEl) {
    const loadingEl = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    buttonEl.innerHTML = loadingEl;
}


function stopLoadingButton(buttonEl, text) {
    buttonEl.innerHTML = text;
}

function removeModalBackdrop() {
    const backdrop = document.getElementsByClassName('modal-backdrop')[0];
    backdrop.remove();
}


form.addEventListener('submit', (e) => {
    e.preventDefault();
    const updateButton = document.getElementById('updateButton');
    loadingButton(updateButton)
    submitForm(form)
    .then(() => {
        stopLoadingButton(updateButton, 'Sign-Up')
    })
});

const declineForm = document.getElementById('declineName');
const declineNameField = document.getElementById('declineNameField');

function submitDeclineForm(form) {
    console.log(form)
    console.log(declineNameField.value);
    const name = declineNameField.value;
    return signInAnonymously(auth)
        .then(() => {
            console.log(auth.currentUser);
            const { uid } = auth.currentUser;
            return writeDeclineData(uid, name)
        })
}

declineForm.addEventListener('submit', (e) => {
    e.preventDefault();
    var declineButton = document.getElementById('declineButton');
    loadingButton(declineButton);
    submitDeclineForm(declineForm)
    .then( () => {
        var myModalEl = document.getElementById('modalDecline');
        var modal = bootstrap.Modal.getInstance(myModalEl)
        stopLoadingButton(declineButton, 'Submit');
        modal.toggle();
        removeModalBackdrop();
    })
    .catch((error) => {
        console.log(error);
        createAlert('danger', declineForm, error)
    })
});






